<template>
  <v-avatar :size="size" :tile="tile">
    <v-img v-bind="$attrs" :src="thumbSrc" :alt="alt" />
  </v-avatar>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
    },
    alt: {
      type: String,
      default: 'Food',
    },
    size: {
      type: String,
      default: '120',
    },
    default_image: {
      default: require('@/assets/images/default-recipe.png'),
    },
    tile: {
      type: Boolean,
    },
  },

  computed: {
    thumbSrc() {
      return this.image ? this.image.medium : this.default_image
    },
  },
}
</script>

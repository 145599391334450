<template>
  <div class="new-program data-entry pb-12 pt-10">
    <v-row>
      <v-col sm="8">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Preview</h2>
          <v-btn
            class="primary--text mr-5"
            text
            depressed
            :loading="form.$busy"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndFinish"
            :loading="form.$busy"
            text
            depressed
          >
            Done
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="pb-12">
      <v-col sm="8">
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="8" class="d-flex align-center">
                <v-list>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline">
                        {{ selectedData.title }}
                      </v-list-item-title>
                      <div class="mb-4">{{ selectedData.description }}</div>
                      <div class="subtitle-1">
                        Quantity per serving:
                        <b>{{ selectedData.serving_quantity }}</b>
                      </div>
                      <div class="subtitle-1">
                        Unit of quantity: <b>{{ selectedData.serving_unit }}</b>
                      </div>
                      <div class="subtitle-1">
                        Categories:
                        <b>
                          {{
                            selectedData.categories | readableEnums(categories)
                          }}
                        </b>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col class="text-right">
                <cloudinary-image
                  :image="selectedData.cloudinary_image"
                  :tile="true"
                  aspect-ratio="1.4"
                  class="primary lighten-4 rounded-lg"
                  alt="Food Recipe"
                  height="240"
                  size="240"
                  contain
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-text>
            <v-list-item>
              <v-list-item-title class="headline mb-1">
                Ingredients
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-for="(item, index) in ingredients" :key="index">
              <v-list-item-title class="custom-item">
                {{ item.food_ingredient.serving_quantity }}
                {{ item.food_ingredient.serving_unit }}
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-card-text>
          <v-divider />

          <v-card-text>
            <v-list-item>
              <v-list-item-title class="headline mb-1">
                Flavour Boosters
              </v-list-item-title>
            </v-list-item>

            <div class="methods-list--container px-2">
              <ol v-if="form.flavour_boosters">
                <li
                  v-for="(flavour_boosters, index) in form.flavour_boosters"
                  :key="index"
                >
                  <div class="d-flex align-center py-3">
                    <span> {{ flavour_boosters }} </span>
                  </div>
                </li>
              </ol>

              <no-list v-else details="No Flavour Boosters" />
            </div>
          </v-card-text>

          <v-card-text>
            <v-list-item>
              <v-list-item-title class="headline mb-1">
                Directions
              </v-list-item-title>
            </v-list-item>

            <div class="methods-list--container px-2">
              <ol v-if="form.methods">
                <li v-for="(method, index) in form.methods" :key="index">
                  <div class="d-flex align-center py-3">
                    <span> {{ method }} </span>
                  </div>
                </li>
              </ol>

              <no-list v-else details="No Directions" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CloudinaryImage from '@/components/elements/CloudinaryImage'
import NoList from '@/components/elements/NoList'
import Event from '@/services/eventBus'
import Form from '@/utils/form'

import { mapActions, mapState } from 'vuex'
import { mdiArrowRight } from '@mdi/js'
import { pick, isNull } from 'lodash'

export default {
  name: 'RecipeDetailsPreviewTab',

  components: {
    CloudinaryImage,
    NoList,
  },

  data() {
    return {
      icons: {
        next: mdiArrowRight,
      },
      form: new Form({}),

      ingredients: [],
    }
  },

  computed: {
    ...mapState({
      selectedData: (state) => state.foods.selectedData,
      categories: (state) => state.foodCategory.list,
    }),
  },

  methods: {
    ...mapActions({
      getSingleData: 'recipe/getSingleData',
      getIngredientData: 'recipe/getIngredientData',
      saveData: 'recipe/saveData',
    }),

    async fetchData(id) {
      let { data } = await this.getSingleData(id)
      this.fillForm(data)

      this.ingredients = await this.getIngredientData(this.selectedData)
    },

    changeImage() {
      this.form.image = this.selectedData.image
    },

    saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      delete payload.image

      this.saveData(payload)
        .then((data) => {
          Event.$emit(
            data.completed_at
              ? 'update-active-recipe-list'
              : 'update-draft-recipe-list'
          )

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: data.completed_at ? 'active.recipe' : 'draft.recipe',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },

    saveAndFinish() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      payload.completed = true
      delete payload.image

      this.saveData(payload)
        .then((data) => {
          Event.$emit('update-active-recipe-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: 'active.recipe',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    fillForm(data) {
      data = pick(data, [
        'id',
        'type',
        'title',
        'description',
        'is_alcohol',
        'is_flavor_booster',
        'is_raw',
        'serving_quantity',
        'serving_unit',
        'calories',
        'protein',
        'fats',
        'carbs',
        'fibre',
        'ethanol',
        'raw',
        'image',
        'categories',
        'food_ingredient',
        'methods',
        'flavour_boosters',
        'completed',
      ])
      if (isNull(data.raw)) {
        data.raw = {
          food_id: null,
          serving_quantity: null,
          serving_unit: null,
        }
      } else {
        data.raw = {
          food_id: data.raw.id,
          serving_quantity: data.raw.serving_quantity,
          serving_unit: data.raw.serving_unit,
        }
      }

      if (isNull(data.categories)) {
        data.categories = []
      }

      this.form = new Form(data)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>
<style scoped>
.v-list-item__title.custom-item {
  text-overflow: inherit;
  white-space: normal;
}

li {
  padding-left: 20px;
}
</style>
